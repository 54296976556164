<template>
    <highcharts  class="chart h-full w-full" :options="options" :ref="chartId" :id="chartId"></highcharts>
</template>

<script>
    export default {
        props: ['data','chartId', 'color', 'name'],
        data: function(){
            return {
                options: {
                    chart: {
                        type: 'spline',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        backgroundColor:'rgba(0,0,0,0)',
                        spacingBottom: 0,
                        // margin: [10,10,10,10]
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    credits:{
                        enabled: false
                    },
                    xAxis: [{
                        type: 'datetime',
                        lineColor: '#768194',
                        title: {
                            text: ''
                        },
                        labels: {
                            enabled: true,
                            style:{
                                color:'#768194',
                                fontSize: '10px'
                            }
                        },
                        tickWidth: 0,
                        tickInterval: 1000 * 60 * 60 * 24 * 2,
                        visible: true
                    }],
                    exporting:{
                        enabled:false
                    },
                    yAxis: {
                        title: {
                            text: ''
                        },
                        gridLineColor: 'rgba(255,255,255,0)',
                        labels: {
                            enabled: false,
                            style:{
                                color:'#768194'
                            },
                        },
                        visible: false
                    },
                    tooltip: {
                        enabled:false
                    },
                    plotOptions: {
                        spline:{
                            fillOpacity:.3,
                            lineWidth:2,
                            marker:{
                                enabled:false
                            }
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    series: [
                        {
                        color: '#606FDD',
                        data: [2,5,4,1,6,8,4,5,9,7,1,5,8,4]
                        }
                    ]
                }
            }
        },
        methods: {
            load() {
                let chart = this.$refs[this.chartId].chart;
                chart.series[0].setData(this.data)
            },
        },
        watch: {
            data: {
                handler(a, b){
                    this.load();
                },
                deep: true
            },
        },
        mounted() {
            this.load();
        }
    };
</script>

<style>

    .highcharts-container{
        height: 100% !important;
        width: 100% !important;
    }

</style>